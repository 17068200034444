import React from 'react';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import {currentLocale} from "../i18n";


const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar locale={currentLocale()} currentPage="privacy-policy">

            </Navbar>
            <div className="has-static-nav">
                <div className="container">
                    <div className="bg bg-small">
                        <h1 className="title">Datenschutzerklärung</h1>

                        <div className="text-content">
                            <div className="content">
                                <p>Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen
                                    besonders hohen Stellenwert für die Geschäftsleitung der adesso SE. Eine Nutzung der
                                    Internetseiten der adesso SE ist grundsätzlich ohne jede Angabe personenbezogener
                                    Daten
                                    möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens über
                                    unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung
                                    personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener
                                    Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche
                                    Grundlage,
                                    holen wir generell eine Einwilligung der betroffenen Person ein.</p>
                                <p>Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift,
                                    E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im
                                    Einklang
                                    mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für die adesso SE
                                    geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser
                                    Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang
                                    und
                                    Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
                                    informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung
                                    über
                                    die ihnen zustehenden Rechte aufgeklärt.</p>
                                <p>Die adesso SE hat als für die Verarbeitung Verantwortlicher zahlreiche technische und
                                    organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über
                                    diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch
                                    können Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
                                    aufweisen,
                                    sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht
                                    es
                                    jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen Wegen,
                                    beispielsweise telefonisch, an uns zu übermitteln.</p>
                                <ol>
                                    <li>Begriffsbestimmungen</li>
                                    <p>Die Datenschutzerklärung der adesso SE beruht auf den Begrifflichkeiten, die
                                        durch
                                        den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der
                                        Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
                                        Datenschutzerklärung
                                        soll sowohl für die Öffentlichkeit als auch für unsere Kunden und
                                        Geschäftspartner
                                        einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir
                                        vorab
                                        die verwendeten Begrifflichkeiten erläutern.</p>
                                    <p>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden
                                        Begriffe:
                                    </p>
                                    <ol className="lower-alpha-list">
                                        <li>personenbezogene Daten</li>
                                        <p>Personenbezogene Daten sind alle Informationen, die sich auf eine
                                            identifizierte
                                            oder identifizierbare natürliche Person (im Folgenden „betroffene Person“)
                                            beziehen. Als identifizierbar wird eine natürliche Person angesehen, die
                                            direkt
                                            oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
                                            Namen,
                                            zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
                                            oder mehreren besonderen Merkmalen, die Ausdruck der physischen,
                                            physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
                                            oder
                                            sozialen Identität dieser natürlichen Person sind, identifiziert werden
                                            kann.</p>

                                        <li>betroffene Person</li>
                                        <p>Betroffene Person ist jede identifizierte oder identifizierbare natürliche
                                            Person, deren personenbezogene Daten von dem für die Verarbeitung
                                            Verantwortlichen verarbeitet werden.</p>

                                        <li>Verarbeitung</li>
                                        <p>Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren
                                            ausgeführte
                                            Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
                                            Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die
                                            Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
                                            Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere
                                            Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
                                            Einschränkung,
                                            das Löschen oder die Vernichtung.</p>

                                        <li> Einschränkung der Verarbeitung</li>
                                        <p>Einschränkung der Verarbeitung ist die Markierung gespeicherter
                                            personenbezogener
                                            Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.</p>

                                        <li> Profiling</li>
                                        <p>Profiling ist jede Art der automatisierten Verarbeitung personenbezogener
                                            Daten,
                                            die darin besteht, dass diese personenbezogenen Daten verwendet werden, um
                                            bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen,
                                            zu
                                            bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung,
                                            wirtschaftlicher
                                            Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit,
                                            Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                                            analysieren oder vorherzusagen.</p>

                                        <li>Pseudonymisierung</li>
                                        <p>Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer
                                            Weise,
                                            auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
                                            Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet
                                            werden
                                            können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden
                                            und
                                            technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten,
                                            dass
                                            die personenbezogenen Daten nicht einer identifizierten oder
                                            identifizierbaren
                                            natürlichen Person zugewiesen werden.</p>

                                        <li> Verantwortlicher oder für die Verarbeitung Verantwortlicher</li>
                                        <p>Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die
                                            natürliche
                                            oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein
                                            oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
                                            personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser
                                            Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten
                                            vorgegeben, so kann der Verantwortliche beziehungsweise können die
                                            bestimmten
                                            Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
                                            Mitgliedstaaten vorgesehen werden.</p>

                                        <li> Auftragsverarbeiter</li>
                                        <p>Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde,
                                            Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
                                            Verantwortlichen verarbeitet.</p>

                                        <li> Empfänger</li>
                                        <p>Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung
                                            oder
                                            andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig
                                            davon,
                                            ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im
                                            Rahmen
                                            eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht
                                            der
                                            Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
                                            jedoch
                                            nicht als Empfänger.</p>

                                        <li> Dritter</li>
                                        <p>Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung
                                            oder
                                            andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem
                                            Auftragsverarbeiter und den Personen, die unter der unmittelbaren
                                            Verantwortung
                                            des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die
                                            personenbezogenen Daten zu verarbeiten.</p>

                                        <li> Einwilligung</li>
                                        <p>Einwilligung ist jede von der betroffenen Person freiwillig für den
                                            bestimmten
                                            Fall in informierter Weise und unmissverständlich abgegebene
                                            Willensbekundung in
                                            Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden
                                            Handlung,
                                            mit der die betroffene Person zu verstehen gibt, dass sie mit der
                                            Verarbeitung
                                            der sie betreffenden personenbezogenen Daten einverstanden ist.</p>
                                    </ol>

                                    <li>Name und Anschrift des für die Verarbeitung Verantwortlichen</li>
                                    <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den
                                        Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer
                                        Bestimmungen mit datenschutzrechtlichem Charakter ist die:</p>
                                    <p>adesso SE</p>
                                    <p>Addessoplatz 1</p>
                                    <p>44269 Dortmund</p>
                                    <p>Deutschland</p>
                                    <p>Tel.: +4923170007000</p>
                                    <p className="mail">E-Mail: team[at]dohack.io</p>
                                    <p>Website: www.dohack.io</p>

                                    <li>Name und Anschrift des Datenschutzbeauftragten</li>
                                    <p>Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist:<br/>
                                        Julius Hüttmann</p>
                                    <p>adesso SE</p>
                                    <p>Addessoplatz 1</p>
                                    <p>44269 Dortmund</p>
                                    <p>Deutschland</p>
                                    <p>Tel.: +4923170007000</p>
                                    <p className="mail">E-Mail: info[at]adesso.de</p>
                                    <p>Website: www.dohack.io</p>
                                    <p>Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum
                                        Datenschutz direkt an unseren Datenschutzbeauftragten wenden.</p>

                                    <li>Cookies</li>
                                    <p>Die Internetseiten der adesso SE verwenden Cookies. Cookies sind Textdateien,
                                        welche
                                        über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert
                                        werden.</p>
                                    <p>Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten
                                        eine
                                        sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies.
                                        Sie
                                        besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem
                                        konkreten
                                        Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde.
                                        Dies
                                        ermöglicht es den besuchten Internetseiten und Servern, den individuellen
                                        Browser
                                        der betroffenen Person von anderen Internetbrowsern, die andere Cookies
                                        enthalten,
                                        zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige
                                        Cookie-ID
                                        wiedererkannt und identifiziert werden.</p>
                                    <p>Durch den Einsatz von Cookies kann die adesso SE den Nutzern dieser Internetseite
                                        nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht
                                        möglich wären.</p>
                                    <p>Mittels eines Cookies können die Informationen und Angebote auf unserer
                                        Internetseite
                                        im Sinne des Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits
                                        erwähnt, die Benutzer unserer Internetseite wiederzuerkennen. Zweck dieser
                                        Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu
                                        erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet, muss
                                        beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
                                        Zugangsdaten
                                        eingeben, weil dies von der Internetseite und dem auf dem Computersystem des
                                        Benutzers abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist das
                                        Cookie
                                        eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die
                                        ein
                                        Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.</p>
                                    <p>Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite
                                        jederzeit mittels einer entsprechenden Einstellung des genutzten
                                        Internetbrowsers
                                        verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Ferner
                                        können
                                        bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere
                                        Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern
                                        möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem
                                        genutzten
                                        Internetbrowser, sind unter Umständen nicht alle Funktionen unserer
                                        Internetseite
                                        vollumfänglich nutzbar.</p>

                                    <li>Erfassung von allgemeinen Daten und Informationen</li>
                                    <p>Die Internetseite der adesso SE erfasst mit jedem Aufruf der Internetseite durch
                                        eine
                                        betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen
                                        Daten
                                        und Informationen. Diese allgemeinen Daten und Informationen werden in den
                                        Logfiles
                                        des Servers gespeichert. Erfasst werden können die (1) verwendeten Browsertypen
                                        und
                                        Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die
                                        Internetseite, von welcher ein zugreifendes System auf unsere Internetseite
                                        gelangt
                                        (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes
                                        System
                                        auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
                                        eines
                                        Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse
                                        (IP-Adresse),
                                        (7) der Internet-Service-Provider des zugreifenden Systems und (8) sonstige
                                        ähnliche
                                        Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf
                                        unsere
                                        informationstechnologischen Systeme dienen.</p>
                                    <p>Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die adesso SE
                                        keine
                                        Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr
                                        benötigt, um (1) die Inhalte unserer Internetseite korrekt auszuliefern, (2) die
                                        Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren, (3) die
                                        dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und
                                        der
                                        Technik unserer Internetseite zu gewährleisten sowie (4) um
                                        Strafverfolgungsbehörden
                                        im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen
                                        bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch die
                                        adesso SE daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den
                                        Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um
                                        letztlich
                                        ein optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
                                        sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von
                                        allen
                                        durch eine betroffene Person angegebenen personenbezogenen Daten
                                        gespeichert.</p>

                                    <li>Registrierung auf unserer Internetseite</li>
                                    <p>Die betroffene Person hat die Möglichkeit, sich auf der Internetseite des für die
                                        Verarbeitung Verantwortlichen unter Angabe von personenbezogenen Daten zu
                                        registrieren. Welche personenbezogenen Daten dabei an den für die Verarbeitung
                                        Verantwortlichen übermittelt werden, ergibt sich aus der jeweiligen
                                        Eingabemaske,
                                        die für die Registrierung verwendet wird. Die von der betroffenen Person
                                        eingegebenen personenbezogenen Daten werden ausschließlich für die interne
                                        Verwendung bei dem für die Verarbeitung Verantwortlichen und für eigene Zwecke
                                        erhoben und gespeichert. Der für die Verarbeitung Verantwortliche kann die
                                        Weitergabe an einen oder mehrere Auftragsverarbeiter, beispielsweise einen
                                        Paketdienstleister, veranlassen, der die personenbezogenen Daten ebenfalls
                                        ausschließlich für eine interne Verwendung, die dem für die Verarbeitung
                                        Verantwortlichen zuzurechnen ist, nutzt.</p>
                                    <p>Durch eine Registrierung auf der Internetseite des für die Verarbeitung
                                        Verantwortlichen wird ferner die vom Internet-Service-Provider (ISP) der
                                        betroffenen
                                        Person vergebene IP-Adresse, das Datum sowie die Uhrzeit der Registrierung
                                        gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur
                                        so
                                        der Missbrauch unserer Dienste verhindert werden kann, und diese Daten im
                                        Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern ist die
                                        Speicherung dieser Daten zur Absicherung des für die Verarbeitung
                                        Verantwortlichen
                                        erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich
                                        nicht,
                                        sofern keine gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der
                                        Strafverfolgung dient.</p>
                                    <p>Die Registrierung der betroffenen Person unter freiwilliger Angabe
                                        personenbezogener
                                        Daten dient dem für die Verarbeitung Verantwortlichen dazu, der betroffenen
                                        Person
                                        Inhalte oder Leistungen anzubieten, die aufgrund der Natur der Sache nur
                                        registrierten Benutzern angeboten werden können. Registrierten Personen steht
                                        die
                                        Möglichkeit frei, die bei der Registrierung angegebenen personenbezogenen Daten
                                        jederzeit abzuändern oder vollständig aus dem Datenbestand des für die
                                        Verarbeitung
                                        Verantwortlichen löschen zu lassen.</p>
                                    <p>Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person
                                        jederzeit
                                        auf Anfrage Auskunft darüber, welche personenbezogenen Daten über die betroffene
                                        Person gespeichert sind. Ferner berichtigt oder löscht der für die Verarbeitung
                                        Verantwortliche personenbezogene Daten auf Wunsch oder Hinweis der betroffenen
                                        Person, soweit dem keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Die
                                        Gesamtheit der Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
                                        betroffenen Person in diesem Zusammenhang als Ansprechpartner zur Verfügung.</p>
                                    <p>Die aufgenommenen Fotos und Videos werden nur im Kontext dieser und zukünftiger
                                        Veranstaltungen genutzt und beispielsweise auf unserer Webseite,
                                        Online-Plattformen
                                        oder nach Rücksprache und Einwilligung für weitere Zwecke der medialen
                                        Verwertung (z.B. Informationsmaterialien, Werbung)
                                        der Veranstaltung verwendet.</p>

                                    <li>Routinemäßige Löschung und Sperrung von personenbezogenen Daten</li>
                                    <p>Der für die Verarbeitung Verantwortliche verarbeitet und speichert
                                        personenbezogene
                                        Daten der betroffenen Person nur für den Zeitraum, der zur Erreichung des
                                        Speicherungszwecks erforderlich ist oder sofern dies durch den Europäischen
                                        Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen
                                        oder
                                        Vorschriften, welchen der für die Verarbeitung Verantwortliche unterliegt,
                                        vorgesehen wurde.</p>
                                    <p>Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und
                                        Verordnungsgeber oder einem anderen zuständigen Gesetzgeber vorgeschriebene
                                        Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und
                                        entsprechend
                                        den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>

                                    <li>Rechte der betroffenen Person</li>

                                    <ol className="lower-alpha-list">
                                        <li>Recht auf Bestätigung</li>
                                        <p>Jede betroffene Person hat das vom Europäischen Richtlinien- und
                                            Verordnungsgeber
                                            eingeräumte Recht, von dem für die Verarbeitung Verantwortlichen eine
                                            Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten
                                            verarbeitet werden. Möchte eine betroffene Person dieses Bestätigungsrecht
                                            in
                                            Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
                                            die
                                            Verarbeitung Verantwortlichen wenden.</p>

                                        <li>Recht auf Auskunft</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von
                                            dem
                                            für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu
                                            seiner
                                            Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft
                                            zu
                                            erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der
                                            betroffenen Person Auskunft über folgende Informationen zugestanden:<br/>
                                                die Verarbeitungszwecke<br/>
                                                die Kategorien personenbezogener Daten, die verarbeitet werden<br/>
                                                die Empfänger oder Kategorien von Empfängern, gegenüber denen die
                                                personenbezogenen Daten offengelegt worden sind oder noch offengelegt
                                                werden,
                                                insbesondere bei Empfängern in Drittländern oder bei internationalen
                                                Organisationen<br/>
                                                falls möglich die geplante Dauer, für die die personenbezogenen Daten
                                                gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien
                                                für die
                                                Festlegung dieser Dauer<br/>
                                                das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
                                                betreffenden
                                                personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch
                                                den
                                                Verantwortlichen oder eines Widerspruchsrechts gegen diese
                                                Verarbeitung<br/>
                                                das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde<br/>
                                                wenn die personenbezogenen Daten nicht bei der betroffenen Person
                                                erhoben
                                                werden: Alle verfügbaren Informationen über die Herkunft der Daten<br/>
                                                das Bestehen einer automatisierten Entscheidungsfindung einschließlich
                                                Profiling
                                                gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen —
                                                aussagekräftige Informationen über die involvierte Logik sowie die
                                                Tragweite und
                                                die angestrebten Auswirkungen einer derartigen Verarbeitung für die
                                                betroffene
                                                Person</p>

                                        <p>Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
                                            personenbezogene Daten an ein Drittland oder an eine internationale
                                            Organisation
                                            übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen
                                            Person im
                                            Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang
                                            mit
                                            der Übermittlung zu erhalten.</p>

                                        <p>Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann
                                            sie
                                            sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
                                            Verantwortlichen wenden.</p>

                                        <li>Recht auf Berichtigung</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die
                                            unverzügliche
                                            Berichtigung sie betreffender unrichtiger personenbezogener Daten zu
                                            verlangen.
                                            Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der
                                            Zwecke der Verarbeitung, die Vervollständigung unvollständiger
                                            personenbezogener
                                            Daten — auch mittels einer ergänzenden Erklärung — zu verlangen.</p>

                                        <p>Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen,
                                            kann
                                            sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
                                            Verantwortlichen wenden.</p>

                                        <li>Recht auf Löschung (Recht auf Vergessen werden)</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
                                            Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen
                                            Daten
                                            unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und
                                            soweit die Verarbeitung nicht erforderlich ist:<br/>
                                                Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf
                                                sonstige
                                                Weise verarbeitet, für welche sie nicht mehr notwendig sind.<br/>
                                                Die betroffene Person widerruft ihre Einwilligung, auf die sich die
                                                Verarbeitung
                                                gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a
                                                DS-GVO
                                                stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
                                                Verarbeitung.<br/>
                                                Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen
                                                die
                                                Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe
                                                für die
                                                Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2
                                                DS-GVO
                                                Widerspruch gegen die Verarbeitung ein.<br/>
                                                Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br/>
                                                Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
                                                rechtlichen
                                                Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                                                erforderlich, dem der Verantwortliche unterliegt.<br/>
                                                Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                                                Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.<br/>
                                        </p>
                                        <p>Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person
                                            die
                                            Löschung von personenbezogenen Daten, die bei der adesso SE gespeichert
                                            sind,
                                            veranlassen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des
                                            für
                                            die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der adesso SE wird
                                            veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.
                                        </p>
                                        <p>Wurden die personenbezogenen Daten von der adesso SE öffentlich gemacht und
                                            ist
                                            unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur
                                            Löschung
                                            der personenbezogenen Daten verpflichtet, so trifft die adesso SE unter
                                            Berücksichtigung der verfügbaren Technologie und der Implementierungskosten
                                            angemessene Maßnahmen, auch technischer Art, um andere für die
                                            Datenverarbeitung
                                            Verantwortliche, welche die veröffentlichten personenbezogenen Daten
                                            verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von
                                            diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung
                                            sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder
                                            Replikationen dieser personenbezogenen Daten verlangt hat, soweit die
                                            Verarbeitung nicht erforderlich ist. Der Mitarbeiter der adesso SE wird im
                                            Einzelfall das Notwendige veranlassen.
                                        </p>

                                        <li>Recht auf Einschränkung der Verarbeitung</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
                                            Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine
                                            der
                                            folgenden Voraussetzungen gegeben ist:<br/>
                                                Die Richtigkeit der personenbezogenen Daten wird von der betroffenen
                                                Person
                                                bestritten, und zwar für eine Dauer, die es dem Verantwortlichen
                                                ermöglicht, die
                                                Richtigkeit der personenbezogenen Daten zu überprüfen.<br/>
                                                Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die
                                                Löschung der
                                                personenbezogenen Daten ab und verlangt stattdessen die Einschränkung
                                                der
                                                Nutzung der personenbezogenen Daten.<br/>
                                                Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke
                                                der
                                                Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur
                                                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br/>
                                                Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art.
                                                21 Abs. 1
                                                DS-GVO eingelegt und es steht noch nicht fest, ob die berechtigten
                                                Gründe des
                                                Verantwortlichen gegenüber denen der betroffenen Person überwiegen.<br/>
                                        </p>
                                        <p>Sofern eine der oben genannten Voraussetzungen gegeben ist und eine
                                            betroffene
                                            Person die Einschränkung von personenbezogenen Daten, die bei der adesso SE
                                            gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit an einen
                                            Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der
                                            Mitarbeiter
                                            der adesso SE wird die Einschränkung der Verarbeitung veranlassen.
                                        </p>

                                        <li>Recht auf Datenübertragbarkeit</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie
                                            betreffenden personenbezogenen Daten, welche durch die betroffene Person
                                            einem
                                            Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen
                                            und
                                            maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht, diese
                                            Daten
                                            einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen,
                                            dem
                                            die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
                                            die
                                            Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO
                                            oder
                                            Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1
                                            Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter
                                            Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer
                                            Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in
                                            Ausübung
                                            öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
                                        </p>
                                        <p>Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf
                                            Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken,
                                            dass
                                            die personenbezogenen Daten direkt von einem Verantwortlichen an einen
                                            anderen
                                            Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und
                                            sofern hiervon nicht die Rechte und Freiheiten anderer Personen
                                            beeinträchtigt
                                            werden.
                                        </p>
                                        <p>Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die
                                            betroffene
                                            Person jederzeit an einen Mitarbeiter der adesso SE wenden.
                                        </p>

                                        <li>Recht auf Widerspruch</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen,
                                            die
                                            sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
                                            Verarbeitung
                                            sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1
                                            Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch
                                            für
                                            ein auf diese Bestimmungen gestütztes Profiling.
                                        </p>
                                        <p>Die adesso SE verarbeitet die personenbezogenen Daten im Falle des
                                            Widerspruchs
                                            nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die
                                            Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der
                                            betroffenen Person überwiegen, oder die Verarbeitung dient der
                                            Geltendmachung,
                                            Ausübung oder Verteidigung von Rechtsansprüchen.
                                        </p>
                                        <p>Verarbeitet die adesso SE personenbezogene Daten, um Direktwerbung zu
                                            betreiben,
                                            so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die
                                            Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung
                                            einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher
                                            Direktwerbung in Verbindung steht. Widerspricht die betroffene Person
                                            gegenüber
                                            der adesso SE der Verarbeitung für Zwecke der Direktwerbung, so wird die
                                            adesso
                                            AG die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
                                        </p>
                                        <p>Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer
                                            besonderen Situation ergeben, gegen die sie betreffende Verarbeitung
                                            personenbezogener Daten, die bei der adesso SE zu wissenschaftlichen oder
                                            historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89
                                            Abs.
                                            1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
                                            Verarbeitung
                                            ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
                                            erforderlich.
                                        </p>
                                        <p>Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person
                                            direkt
                                            jeden Mitarbeiter der adesso SE oder einen anderen Mitarbeiter wenden. Der
                                            betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von
                                            Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG,
                                            ihr
                                            Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen
                                            technische Spezifikationen verwendet werden.
                                        </p>

                                        <li>Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer
                                            ausschließlich auf einer automatisierten Verarbeitung — einschließlich
                                            Profiling
                                            — beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber
                                            rechtliche
                                            Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt,
                                            sofern
                                            die Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines
                                            Vertrags
                                            zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist,
                                            oder
                                            (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
                                            denen
                                            der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften
                                            angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der
                                            berechtigten Interessen der betroffenen Person enthalten oder (3) mit
                                            ausdrücklicher Einwilligung der betroffenen Person erfolgt.
                                        </p>
                                        <p>Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags
                                            zwischen der betroffenen Person und dem Verantwortlichen erforderlich oder
                                            (2)
                                            erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person, trifft
                                            die
                                            adesso SE angemessene Maßnahmen, um die Rechte und Freiheiten sowie die
                                            berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens
                                            das
                                            Recht auf Erwirkung des Eingreifens einer Person seitens des
                                            Verantwortlichen,
                                            auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
                                            gehört.
                                        </p>
                                        <p>Möchte die betroffene Person Rechte mit Bezug auf automatisierte
                                            Entscheidungen
                                            geltend machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
                                            die
                                            Verarbeitung Verantwortlichen wenden.
                                        </p>

                                        <li>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</li>
                                        <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                                            vom
                                            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine
                                            Einwilligung
                                            zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
                                        </p>
                                        <p>Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung
                                            geltend
                                            machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
                                            Verarbeitung Verantwortlichen wenden.
                                        </p>
                                    </ol>

                                    <li>Datenschutzbestimmungen zu Einsatz und Verwendung von Facebook</li>
                                    <p>Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten
                                        des
                                        Unternehmens Facebook integriert. Facebook ist ein soziales Netzwerk.</p>

                                    <p>Ein soziales Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine
                                        Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht, untereinander
                                        zu
                                        kommunizieren und im virtuellen Raum zu interagieren. Ein soziales Netzwerk kann
                                        als
                                        Plattform zum Austausch von Meinungen und Erfahrungen dienen oder ermöglicht es
                                        der
                                        Internetgemeinschaft, persönliche oder unternehmensbezogene Informationen
                                        bereitzustellen. Facebook ermöglicht den Nutzern des sozialen Netzwerkes unter
                                        anderem die Erstellung von privaten Profilen, den Upload von Fotos und eine
                                        Vernetzung über Freundschaftsanfragen.</p>

                                    <p>Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo
                                        Park,
                                        CA 94025, USA. Für die Verarbeitung personenbezogener Daten Verantwortlicher
                                        ist,
                                        wenn eine betroffene Person außerhalb der USA oder Kanada lebt, die Facebook
                                        Ireland
                                        Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.</p>

                                    <p>Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für
                                        die
                                        Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                                        Facebook-Komponente (Facebook-Plug-In) integriert wurde, wird der
                                        Internetbrowser
                                        auf dem informationstechnologischen System der betroffenen Person automatisch
                                        durch
                                        die jeweilige Facebook-Komponente veranlasst, eine Darstellung der
                                        entsprechenden
                                        Facebook-Komponente von Facebook herunterzuladen. Eine Gesamtübersicht über alle
                                        Facebook-Plug-Ins kann unter
                                        <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE" className="link link-orange">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>
                                        abgerufen werden. Im
                                        Rahmen dieses technischen Verfahrens erhält Facebook Kenntnis darüber, welche
                                        konkrete Unterseite unserer Internetseite durch die betroffene Person besucht
                                        wird.</p>

                                    <p>Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt ist, erkennt
                                        Facebook mit jedem Aufruf unserer Internetseite durch die betroffene Person und
                                        während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer
                                        Internetseite,
                                        welche konkrete Unterseite unserer Internetseite die betroffene Person besucht.
                                        Diese Informationen werden durch die Facebook-Komponente gesammelt und durch
                                        Facebook dem jeweiligen Facebook-Account der betroffenen Person zugeordnet.
                                        Betätigt
                                        die betroffene Person einen der auf unserer Internetseite integrierten
                                        Facebook-Buttons, beispielsweise den „Gefällt mir“-Button, oder gibt die
                                        betroffene
                                        Person einen Kommentar ab, ordnet Facebook diese Information dem persönlichen
                                        Facebook-Benutzerkonto der betroffenen Person zu und speichert diese
                                        personenbezogenen Daten.</p>

                                    <p>Facebook erhält über die Facebook-Komponente immer dann eine Information darüber,
                                        dass die betroffene Person unsere Internetseite besucht hat, wenn die betroffene
                                        Person zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Facebook
                                        eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person die
                                        Facebook-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser
                                        Informationen an Facebook von der betroffenen Person nicht gewollt, kann diese
                                        die
                                        Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
                                        Internetseite aus ihrem Facebook-Account ausloggt.</p>

                                    <p>Die von Facebook veröffentlichte Datenrichtlinie, die unter
                                        <a href="https://de-de.facebook.com/about/privacy/" className="link link-orange">https://de-de.facebook.com/about/privacy/</a>
                                        abrufbar ist, gibt Aufschluss über die
                                        Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch Facebook.
                                        Ferner
                                        wird dort erläutert, welche Einstellungsmöglichkeiten Facebook zum Schutz der
                                        Privatsphäre der betroffenen Person bietet. Zudem sind unterschiedliche
                                        Applikationen erhältlich, die es ermöglichen, eine Datenübermittlung an Facebook
                                        zu
                                        unterdrücken. Solche Applikationen können durch die betroffene Person genutzt
                                        werden, um eine Datenübermittlung an Facebook zu unterdrücken.</p>

                                    <li>Datenschutzbestimmungen zu Einsatz und Verwendung von Google Maps</li>
                                    <p>Diese Webseite nutzt Google Maps zur Darstellung interaktiver Karten und zur
                                        Erstellung von Anfahrtsbeschreibungen. Google Maps ist ein Kartendienst von
                                        Google
                                        Inc., 1600 Amphitheatre Parkway, Mountain View, California 94043, USA. Durch die
                                        Nutzung von Google Maps können Informationen über die Benutzung dieser Website
                                        einschließlich Ihrer IP-Adresse und der im Rahmen der Routenplanerfunktion
                                        eingegebenen (Start-) Adresse an Google in den USA übertragen werden. Wenn Sie
                                        eine
                                        Webseite unseres Internetauftritts aufrufen, die Google Maps enthält, baut Ihr
                                        Browser eine direkte Verbindung mit den Servern von Google auf. Der Karteninhalt
                                        wird von Google direkt an Ihren Browser übermittelt und von diesem in die
                                        Webseite
                                        eingebunden. Daher haben wir keinen Einfluss auf den Umfang der auf diese Weise
                                        von
                                        Google erhobenen Daten. Entsprechend unserem Kenntnisstand sind dies zumindest
                                        folgende Daten: </p>
                                    <ul>
                                        <li>Datum und Uhrzeit des Besuchs auf der betreffenden Webseite,</li>
                                        <li>Internetadresse oder URL der aufgerufenen Webseite,</li>
                                        <li>IP-Adresse, im Rahmen der Routenplanung eingegebene (Start-)Anschrift.</li>
                                    </ul>

                                    <p>Auf die weitere Verarbeitung und Nutzung der Daten durch Google haben wir keinen
                                        Einfluss und können daher hierfür keine Verantwortung übernehmen.</p>
                                    <p>Wenn Sie nicht möchten, dass Google über unseren Internetauftritt Daten über Sie
                                        erhebt, verarbeitet oder nutzt, können Sie in Ihrem Browsereinstellungen
                                        JavaScript
                                        deaktivieren. In diesem Fall können Sie die Kartenanzeige jedoch nicht
                                        nutzen.</p>
                                    <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der
                                        Daten durch Google sowie Ihre diesbezüglichen Rechte und
                                        Einstellungsmöglichkeiten
                                        zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von
                                        Google (https://policies.google.com/privacy?hl=de).</p>
                                    <p>Durch die Nutzung unserer Webseite erklären Sie sich mit der Bearbeitung der über
                                        Sie erhobenen Daten durch Google Maps Routenplaner in der zuvor beschriebenen
                                        Art
                                        und Weise und zu dem zuvor benannten Zweck einverstanden.</p>

                                    <li>Datenschutzbestimmungen zu Einsatz und Verwendung von Google+</li>
                                    <p>Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite als
                                        Komponente
                                        die Google+ Schaltfläche integriert. Google+ ist ein sogenanntes soziales
                                        Netzwerk.
                                        Ein soziales Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine
                                        Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht, untereinander
                                        zu
                                        kommunizieren und im virtuellen Raum zu interagieren. Ein soziales Netzwerk kann
                                        als
                                        Plattform zum Austausch von Meinungen und Erfahrungen dienen oder ermöglicht es
                                        der
                                        Internetgemeinschaft, persönliche oder unternehmensbezogene Informationen
                                        bereitzustellen. Google+ ermöglicht den Nutzern des sozialen Netzwerkes unter
                                        anderem die Erstellung von privaten Profilen, den Upload von Fotos und eine
                                        Vernetzung über Freundschaftsanfragen.</p>

                                    <p>Betreibergesellschaft von Google+ ist die Google Inc., 1600 Amphitheatre Pkwy,
                                        Mountain View, CA 94043-1351, USA.</p>

                                    <p>Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für
                                        die
                                        Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Google+
                                        Schaltfläche integriert wurde, wird der Internetbrowser auf dem
                                        informationstechnologischen System der betroffenen Person automatisch durch die
                                        jeweilige Google+ Schaltfläche veranlasst, eine Darstellung der entsprechenden
                                        Google+ Schaltfläche von Google herunterzuladen. Im Rahmen dieses technischen
                                        Verfahrens erhält Google Kenntnis darüber, welche konkrete Unterseite unserer
                                        Internetseite durch die betroffene Person besucht wird. Genauere Informationen
                                        zu
                                        Google+ sind unter <a
                                            href="https://developers.google.com/+/" className="link link-orange">https://developers.google.com/+/</a>
                                        abrufbar.</p>

                                    <p>Sofern die betroffene Person gleichzeitig bei Google+ eingeloggt ist, erkennt
                                        Google
                                        mit jedem Aufruf unserer Internetseite durch die betroffene Person und während
                                        der
                                        gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche
                                        konkrete Unterseite unserer Internetseite die betroffene Person besucht. Diese
                                        Informationen werden durch die Google+ Schaltfläche gesammelt und durch Google
                                        dem
                                        jeweiligen Google+-Account der betroffenen Person zugeordnet.</p>

                                    <p>Betätigt die betroffene Person einen der auf unserer Internetseite integrierten
                                        Google+-Buttons und gibt damit eine Google+1 Empfehlung ab, ordnet Google diese
                                        Information dem persönlichen Google+-Benutzerkonto der betroffenen Person zu und
                                        speichert diese personenbezogenen Daten. Google speichert die
                                        Google+1-Empfehlung
                                        der betroffenen Person und macht diese in Übereinstimmung mit den von der
                                        betroffenen Person diesbezüglich akzeptierten Bedingungen öffentlich zugänglich.
                                        Eine von der betroffenen Person auf dieser Internetseite abgegebene
                                        Google+1-Empfehlung wird in der Folge zusammen mit anderen personenbezogenen
                                        Daten,
                                        wie dem Namen des von der betroffenen Person genutzten Google+1-Accounts und dem
                                        in
                                        diesem hinterlegten Foto in anderen Google-Diensten, beispielsweise den
                                        Suchmaschinenergebnissen der Google-Suchmaschine, dem Google-Konto der
                                        betroffenen
                                        Person oder an sonstigen Stellen, beispielsweise auf Internetseiten oder im
                                        Zusammenhang mit Werbeanzeigen, gespeichert und verarbeitet. Ferner ist Google
                                        in
                                        der Lage, den Besuch auf dieser Internetseite mit anderen bei Google
                                        gespeicherten
                                        personenbezogenen Daten zu verknüpfen. Google zeichnet diese personenbezogenen
                                        Informationen ferner mit dem Zweck auf, die unterschiedlichen Dienste von Google
                                        zu
                                        verbessern oder zu optimieren.</p>

                                    <p>Google erhält über die Google+-Schaltfläche immer dann eine Information darüber,
                                        dass
                                        die betroffene Person unsere Internetseite besucht hat, wenn die betroffene
                                        Person
                                        zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Google+
                                        eingeloggt
                                        ist; dies findet unabhängig davon statt, ob die betroffene Person die
                                        Google+-Schaltfläche anklickt oder nicht.</p>

                                    <p>Ist eine Übermittlung personenbezogener Daten an Google von der betroffenen
                                        Person
                                        nicht gewollt, kann diese eine solche Übermittlung dadurch verhindern, dass sie
                                        sich
                                        vor einem Aufruf unserer Internetseite aus ihrem Google+-Account ausloggt.</p>

                                    <p>Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können
                                        unter <a
                                            href="https://www.google.de/intl/de/policies/privacy" className="link link-orange">https://www.google.de/intl/de/policies/privacy/</a>
                                        abgerufen werden. Weitere
                                        Hinweise von Google zur Google+1-Schaltfläche können unter
                                        <a href="https://developers.google.com/+/web/buttons-policy" className="link link-orange">https://developers.google.com/+/web/buttons-policy</a>
                                        abgerufen werden.</p>

                                    <li>Datenschutzbestimmungen zu Einsatz und Verwendung von LinkedIn</li>
                                    <p>Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten
                                        der
                                        LinkedIn Corporation integriert. LinkedIn ist ein Internetbasiertes soziales
                                        Netzwerk, das eine Konnektierung der Nutzer mit bestehenden Geschäftskontakten
                                        sowie
                                        das Knüpfen von neuen Businesskontakten ermöglicht. Über 400 Millionen
                                        registrierte
                                        Personen nutzen LinkedIn in mehr als 200 Ländern. Damit ist LinkedIn derzeit die
                                        größte Plattform für Businesskontakte und eine der meistbesuchten Internetseiten
                                        der
                                        Welt.</p>

                                    <p>Betreibergesellschaft von LinkedIn ist die LinkedIn Corporation, 2029 Stierlin
                                        Court
                                        Mountain View, CA 94043, USA. Für Datenschutzangelegenheiten außerhalb der USA
                                        ist
                                        LinkedIn Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin 2,
                                        Ireland, zuständig.</p>

                                    <p>Bei jedem einzelnen Abruf unserer Internetseite, die mit einer
                                        LinkedIn-Komponente
                                        (LinkedIn-Plug-In) ausgestattet ist, veranlasst diese Komponente, dass der von
                                        der
                                        betroffenen Person verwendete Browser eine entsprechende Darstellung der
                                        Komponente
                                        von LinkedIn herunterlädt. Weitere Informationen zu den LinkedIn-Plug-Ins können
                                        unter <a
                                            href="https://developer.linkedin.com/plugins" className="link link-orange">https://developer.linkedin.com/plugins</a>
                                        abgerufen werden. Im Rahmen dieses
                                        technischen Verfahrens erhält LinkedIn Kenntnis darüber, welche konkrete
                                        Unterseite
                                        unserer Internetseite durch die betroffene Person besucht wird.</p>

                                    <p>Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt ist, erkennt
                                        LinkedIn mit jedem Aufruf unserer Internetseite durch die betroffene Person und
                                        während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer
                                        Internetseite,
                                        welche konkrete Unterseite unserer Internetseite die betroffene Person besucht.
                                        Diese Informationen werden durch die LinkedIn-Komponente gesammelt und durch
                                        LinkedIn dem jeweiligen LinkedIn-Account der betroffenen Person zugeordnet.
                                        Betätigt
                                        die betroffene Person einen auf unserer Internetseite integrierten
                                        LinkedIn-Button,
                                        ordnet LinkedIn diese Information dem persönlichen LinkedIn-Benutzerkonto der
                                        betroffenen Person zu und speichert diese personenbezogenen Daten.</p>

                                    <p>LinkedIn erhält über die LinkedIn-Komponente immer dann eine Information darüber,
                                        dass die betroffene Person unsere Internetseite besucht hat, wenn die betroffene
                                        Person zum Zeitpunkt des Aufrufes unserer Internetseite gleichzeitig bei
                                        LinkedIn
                                        eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person die
                                        LinkedIn-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser
                                        Informationen an LinkedIn von der betroffenen Person nicht gewollt, kann diese
                                        die
                                        Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
                                        Internetseite aus ihrem LinkedIn-Account ausloggt.</p>

                                    <p>LinkedIn bietet unter <a
                                        href="https://www.linkedin.com/psettings/guest-controls" className="link link-orange">https://www.linkedin.com/psettings/guest-controls</a>
                                        die
                                        Möglichkeit, E-Mail-Nachrichten, SMS-Nachrichten und zielgerichtete Anzeigen
                                        abzubestellen sowie Anzeigen-Einstellungen zu verwalten. LinkedIn nutzt ferner
                                        Partner wie Quantcast, Google Analytics, BlueKai, DoubleClick, Nielsen,
                                        Comscore,
                                        Eloqua und Lotame, die Cookies setzen können. Solche Cookies können unter
                                        <a href="https://www.linkedin.com/legal/cookie-policy" className="link link-orange">https://www.linkedin.com/legal/cookie-policy</a>
                                        abgelehnt werden. Die geltenden
                                        Datenschutzbestimmungen von LinkedIn sind unter <a
                                            href="https://www.linkedin.com/legal/privacy-policy" className="link link-orange">https://www.linkedin.com/legal/privacy-policy</a>
                                        abrufbar. Die Cookie-Richtlinie von
                                        LinkedIn ist unter <a
                                            href="https://www.linkedin.com/legal/cookie-policy" className="link link-orange">https://www.linkedin.com/legal/cookie-policy</a>
                                        abrufbar.</p>

                                    <li>Datenschutzbestimmungen zu Einsatz und Verwendung von Twitter</li>
                                    <p>Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten
                                        von
                                        Twitter integriert. Twitter ist ein multilingualer öffentlich zugänglicher
                                        Mikroblogging-Dienst, auf welchem die Nutzer sogenannte Tweets, also
                                        Kurznachrichten, die auf 280 Zeichen begrenzt sind, veröffentlichen und
                                        verbreiten
                                        können. Diese Kurznachrichten sind für jedermann, also auch für nicht bei
                                        Twitter
                                        angemeldete Personen abrufbar. Die Tweets werden aber auch den sogenannten
                                        Followern
                                        des jeweiligen Nutzers angezeigt. Follower sind andere Twitter-Nutzer, die den
                                        Tweets eines Nutzers folgen. Ferner ermöglicht Twitter über Hashtags,
                                        Verlinkungen
                                        oder Retweets die Ansprache eines breiten Publikums.</p>

                                    <p>Betreibergesellschaft von Twitter ist die Twitter, Inc., 1355 Market Street,
                                        Suite
                                        900, San Francisco, CA 94103, USA.</p>

                                    <p>Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für
                                        die
                                        Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                                        Twitter-Komponente
                                        (Twitter-Button) integriert wurde, wird der Internetbrowser auf dem
                                        informationstechnologischen System der betroffenen Person automatisch durch die
                                        jeweilige Twitter-Komponente veranlasst, eine Darstellung der entsprechenden
                                        Twitter-Komponente von Twitter herunterzuladen. Weitere Informationen zu den
                                        Twitter-Buttons sind unter <a
                                            href="https://about.twitter.com/de/resources/buttons" className="link link-orange">https://about.twitter.com/de/resources/buttons</a>
                                        abrufbar.
                                        Im Rahmen dieses technischen Verfahrens erhält Twitter Kenntnis darüber, welche
                                        konkrete Unterseite unserer Internetseite durch die betroffene Person besucht
                                        wird.
                                        Zweck der Integration der Twitter-Komponente ist es, unseren Nutzern eine
                                        Weiterverbreitung der Inhalte diese Internetseite zu ermöglichen, diese
                                        Internetseite in der digitalen Welt bekannt zu machen und unsere Besucherzahlen
                                        zu
                                        erhöhen.</p>

                                    <p>Sofern die betroffene Person gleichzeitig bei Twitter eingeloggt ist, erkennt
                                        Twitter
                                        mit jedem Aufruf unserer Internetseite durch die betroffene Person und während
                                        der
                                        gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche
                                        konkrete Unterseite unserer Internetseite die betroffene Person besucht. Diese
                                        Informationen werden durch die Twitter-Komponente gesammelt und durch Twitter
                                        dem
                                        jeweiligen Twitter-Account der betroffenen Person zugeordnet. Betätigt die
                                        betroffene Person einen der auf unserer Internetseite integrierten
                                        Twitter-Buttons,
                                        werden die damit übertragenen Daten und Informationen dem persönlichen
                                        Twitter-Benutzerkonto der betroffenen Person zugeordnet und von Twitter
                                        gespeichert
                                        und verarbeitet.</p>

                                    <p>Twitter erhält über die Twitter-Komponente immer dann eine Information darüber,
                                        dass
                                        die betroffene Person unsere Internetseite besucht hat, wenn die betroffene
                                        Person
                                        zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Twitter
                                        eingeloggt
                                        ist; dies findet unabhängig davon statt, ob die betroffene Person die
                                        Twitter-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser
                                        Informationen an Twitter von der betroffenen Person nicht gewollt, kann diese
                                        die
                                        Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
                                        Internetseite aus ihrem Twitter-Account ausloggt.</p>

                                    <p>Die geltenden Datenschutzbestimmungen von Twitter sind unter
                                        <a href="https://twitter.com/privacy?lang=de" className="link link-orange">https://twitter.com/privacy?lang=de</a>
                                        abrufbar.</p>

                                    <li>Datenschutzbestimmungen zu Einsatz und Verwendung von Xing</li>
                                    <p>Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten
                                        von
                                        Xing integriert. Xing ist ein Internetbasiertes soziales Netzwerk, das die
                                        Konnektierung der Nutzer mit bestehenden Geschäftskontakten sowie das Knüpfen
                                        von
                                        neuen Businesskontakten ermöglicht. Die einzelnen Nutzer können bei Xing ein
                                        persönliches Profil von sich anlegen. Unternehmen können beispielsweise
                                        Unternehmensprofile erstellen oder Stellenangebote auf Xing veröffentlichen.</p>

                                    <p>Betreibergesellschaft von Xing ist die XING SE, Dammtorstraße 30, 20354 Hamburg,
                                        Deutschland.</p>

                                    <p>Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für
                                        die
                                        Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                                        Xing-Komponente
                                        (Xing-Plug-In) integriert wurde, wird der Internetbrowser auf dem
                                        informationstechnologischen System der betroffenen Person automatisch durch die
                                        jeweilige Xing-Komponente veranlasst, eine Darstellung der entsprechenden
                                        Xing-Komponente von Xing herunterzuladen. Weitere Informationen zum den
                                        Xing-Plug-Ins können unter <a
                                            href="https://dev.xing.com/plugins" className="link link-orange">https://dev.xing.com/plugins</a>
                                        abgerufen werden. Im Rahmen
                                        dieses technischen Verfahrens erhält Xing Kenntnis darüber, welche konkrete
                                        Unterseite unserer Internetseite durch die betroffene Person besucht wird.</p>

                                    <p>Sofern die betroffene Person gleichzeitig bei Xing eingeloggt ist, erkennt Xing
                                        mit
                                        jedem Aufruf unserer Internetseite durch die betroffene Person und während der
                                        gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche
                                        konkrete Unterseite unserer Internetseite die betroffene Person besucht. Diese
                                        Informationen werden durch die Xing-Komponente gesammelt und durch Xing dem
                                        jeweiligen Xing-Account der betroffenen Person zugeordnet. Betätigt die
                                        betroffene
                                        Person einen der auf unserer Internetseite integrierten Xing-Buttons,
                                        beispielsweise
                                        den „Share“-Button, ordnet Xing diese Information dem persönlichen
                                        Xing-Benutzerkonto der betroffenen Person zu und speichert diese
                                        personenbezogenen
                                        Daten.</p>

                                    <p>Xing erhält über die Xing-Komponente immer dann eine Information darüber, dass
                                        die
                                        betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person
                                        zum
                                        Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Xing eingeloggt
                                        ist;
                                        dies findet unabhängig davon statt, ob die betroffene Person die Xing-Komponente
                                        anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an
                                        Xing
                                        von der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch
                                        verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
                                        Xing-Account ausloggt.</p>

                                    <p>Die von Xing veröffentlichten Datenschutzbestimmungen, die unter
                                        <a href="https://www.xing.com/privacy" className="link link-orange">https://www.xing.com/privacy</a> abrufbar
                                        sind, geben Aufschluss über die Erhebung,
                                        Verarbeitung und Nutzung personenbezogener Daten durch Xing. Ferner hat Xing
                                        unter
                                        <a href="https://www.xing.com/app/share?op=data_protection" className="link link-orange">https://www.xing.com/app/share?op=data_protection</a>
                                        Datenschutzhinweise für den
                                        XING-Share-Button veröffentlicht.</p>

                                    <li>Datenschutzbestimmungen zu Einsatz und Verwendung von YouTube</li>
                                    <p>Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten
                                        von
                                        YouTube integriert. YouTube ist ein Internet-Videoportal, dass Video-Publishern
                                        das
                                        kostenlose Einstellen von Videoclips und anderen Nutzern die ebenfalls
                                        kostenfreie
                                        Betrachtung, Bewertung und Kommentierung dieser ermöglicht. YouTube gestattet
                                        die
                                        Publikation aller Arten von Videos, weshalb sowohl komplette Film- und
                                        Fernsehsendungen, aber auch Musikvideos, Trailer oder von Nutzern selbst
                                        angefertigte Videos über das Internetportal abrufbar sind.</p>

                                    <p>Betreibergesellschaft von YouTube ist die YouTube, LLC, 901 Cherry Ave., San
                                        Bruno,
                                        CA 94066, USA. Die YouTube, LLC ist einer Tochtergesellschaft der Google Inc.,
                                        1600
                                        Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.</p>

                                    <p>Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für
                                        die
                                        Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                                        YouTube-Komponente
                                        (YouTube-Video) integriert wurde, wird der Internetbrowser auf dem
                                        informationstechnologischen System der betroffenen Person automatisch durch die
                                        jeweilige YouTube-Komponente veranlasst, eine Darstellung der entsprechenden
                                        YouTube-Komponente von YouTube herunterzuladen. Weitere Informationen zu YouTube
                                        können unter <a
                                            href="https://www.youtube.com/yt/about/de/" className="link link-orange">https://www.youtube.com/yt/about/de/</a>
                                        abgerufen werden. Im Rahmen dieses
                                        technischen Verfahrens erhalten YouTube und Google Kenntnis darüber, welche
                                        konkrete
                                        Unterseite unserer Internetseite durch die betroffene Person besucht wird.</p>

                                    <p>Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt ist, erkennt
                                        YouTube
                                        mit dem Aufruf einer Unterseite, die ein YouTube-Video enthält, welche konkrete
                                        Unterseite unserer Internetseite die betroffene Person besucht. Diese
                                        Informationen
                                        werden durch YouTube und Google gesammelt und dem jeweiligen YouTube-Account der
                                        betroffenen Person zugeordnet.</p>

                                    <p>YouTube und Google erhalten über die YouTube-Komponente immer dann eine
                                        Information
                                        darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn die
                                        betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig
                                        bei
                                        YouTube eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene
                                        Person
                                        ein YouTube-Video anklickt oder nicht. Ist eine derartige Übermittlung dieser
                                        Informationen an YouTube und Google von der betroffenen Person nicht gewollt,
                                        kann
                                        diese die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
                                        unserer
                                        Internetseite aus ihrem YouTube-Account ausloggt.</p>

                                    <p>Die von YouTube veröffentlichten Datenschutzbestimmungen, die unter
                                        <a href="https://www.google.de/intl/de/policies/privacy/" className="link link-orange">https://www.google.de/intl/de/policies/privacy/</a>
                                        abrufbar sind, geben Aufschluss über
                                        die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch YouTube und
                                        Google.</p>

                                    <li>Rechtsgrundlage der Verarbeitung</li>
                                    <p>Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für
                                        Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten
                                        Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur
                                        Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
                                        erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist,
                                        die
                                        für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
                                        Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b
                                        DS-GVO.
                                        Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung
                                        vorvertraglicher
                                        Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten
                                        oder
                                        Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch
                                        welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie
                                        beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung
                                        auf
                                        Art. 6 I lit. c DS-GVO. In seltenen Fällen könnte die Verarbeitung von
                                        personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der
                                        betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre
                                        beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden
                                        würde
                                        und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige
                                        lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
                                        weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d
                                        DS-GVO
                                        beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO
                                        beruhen.
                                        Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der
                                        vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung
                                        eines berechtigten Interesses unseres Unternehmens oder eines Dritten
                                        erforderlich
                                        ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen
                                        nicht
                                        überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb
                                        gestattet,
                                        weil sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat
                                        insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein könnte,
                                        wenn die betroffene Person ein Kunde des Verantwortlichen ist (Erwägungsgrund 47
                                        Satz 2 DS-GVO).</p>

                                    <li>Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder
                                        einem
                                        Dritten verfolgt werden
                                    </li>

                                    <p>Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO
                                        ist
                                        unser berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit
                                        zugunsten
                                        des Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.</p>

                                    <li>Dauer, für die die personenbezogenen Daten gespeichert werden</li>
                                    <p>Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die
                                        jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die
                                        entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur
                                        Vertragserfüllung oder Vertragsanbahnung erforderlich sind.</p>

                                    <li>Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
                                        personenbezogenen
                                        Daten; Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen
                                        Person, die personenbezogenen Daten bereitzustellen; mögliche Folgen der
                                        Nichtbereitstellung
                                    </li>
                                    <p>Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum
                                        Teil
                                        gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus
                                        vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.
                                        Mitunter
                                        kann es zu einem Vertragsschluss erforderlich sein, dass eine betroffene Person
                                        uns
                                        personenbezogene Daten zur Verfügung stellt, die in der Folge durch uns
                                        verarbeitet
                                        werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns
                                        personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr einen
                                        Vertrag
                                        abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte zur
                                        Folge,
                                        dass der Vertrag mit dem Betroffenen nicht geschlossen werden könnte. Vor einer
                                        Bereitstellung personenbezogener Daten durch den Betroffenen muss sich der
                                        Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den
                                        Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der
                                        personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den
                                        Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die
                                        personenbezogenen Daten bereitzustellen, und welche Folgen die
                                        Nichtbereitstellung
                                        der personenbezogenen Daten hätte.</p>

                                    <li>Bestehen einer automatisierten Entscheidungsfindung</li>
                                    <p>Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
                                        Entscheidungsfindung oder ein Profiling.</p>

                                    <p>Diese Datenschutzerklärung wurde durch den Datenschutzerklärungs-Generator der
                                        DGD
                                        Deutsche Gesellschaft für Datenschutz GmbH, die als Externer
                                        Datenschutzbeauftragter
                                        Essen tätig ist, in Kooperation mit den Datenschutz Anwälten der Kanzlei WILDE
                                        BEUGER SOLMECKE | Rechtsanwälte erstellt.</p>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default () => {
    return (<PrivacyPolicy />);
};
